<template>
    <v-form v-model="valid" ref="order2baForm">
        <div class="d-flex justify-center pt-3">
            <v-btn @click="open2Ba()">
                Open 2BA
                <v-icon size="14" class="ml-2">far fa-arrow-up-right-from-square</v-icon>
            </v-btn>
        </div>

        <div class="d-flex flex-column gap-3 my-5" v-if="responseUniFeed">
            <v-text-field
                v-model="name2ba"
                :rules="[v => v.length > 0 || 'Mag niet leeg zijn']"
                hide-details
                outlined
                dense
                type="text"
                label="Naam*"
                readonly
            />
            <v-text-field
                v-model="quantity"
                :rules="[v => v > 0 || 'Moet minimaal 1 zijn']"
                hide-details
                outlined
                dense
                type="number"
                min="0"
                label="Aantal*"
            />
            <v-text-field
                v-model="description"
                v-if="!workOrderId"
                :rules="[v => null !== v && v.length > 0 || 'Mag niet leeg zijn']"
                hide-details
                outlined
                dense
                type="text"
                label="Omschrijving*"
            />
            <div class="d-flex">
                <v-btn
                    color="green"
                    class="white--text ml-auto"
                    @click="orderFrom2ba"
                    :disabled="!valid"
                >
                    <v-icon size="16" class="mr-2">far fa-shopping-cart</v-icon>
                    Bestellen
                </v-btn>
            </div>

        </div>
    </v-form>
</template>

<script>
import {serialize} from '@/utils/utils.js'
import apiClient from "@/api"

export default {
    name: "MaterialOrder2ba",
    props: {
        workOrderId: {type: Number, default: null, required: false},
    },
    data() {
        return {
            responseUniFeed: null,
            name2ba: '',
            quantity: 1,
            loading2ba: false,
            valid: false,
            description: null,
        }
    },
    methods: {
        async open2Ba(query) {
            const token = await apiClient.get('/2ba/get-access-token')
                .then(response => {
                    return response.data.accessToken
                })

            let hUrl = window.location.href.split('#')[0]
            let params = {
                accessToken: token,
                interfaceType: 'JSONGET',
                interface: 4, // Select 1 trade item
                hookUrl: hUrl + '#2ba',
                query: query ?? '',
            }

            let url = 'https://unifeed.2ba.nl/?' + serialize(params)
            window.location.assign(url)
        },
        orderFrom2ba() {
            if (!this.valid) {
                return
            }

            let materialOrder = {
                workOrder: this.workOrderId,
                orderType: '2ba',
                quantity: this.quantity,
                articleData2ba: this.responseUniFeed,
                description: this.description,
            }

            this.$store.dispatch('newMaterialOrder', {materialOrder})
                .then(() => {
                    this.$store.dispatch('showSnackbar', {text: 'Artikel besteld'})
                    this.$refs.order2baForm.reset()
                    this.responseUniFeed = null
                    this.$emit('closeDialog')
                    this.$router.push(this.$route.path)
                })
                .catch(() => {
                    this.$store.dispatch('showSnackbar', {text: 'Er ging iets mis', color: 'red darken2'})
                })
        },
    },
    mounted() {
        if (this.$route.hash && this.$route.hash.slice(0, 4) === '#2ba') {
            const rawJson = this.$route.hash.split('json=')[1]
            const data = JSON.parse(decodeURIComponent(rawJson))
            this.responseUniFeed = data[0]
            this.name2ba = this.responseUniFeed.Description
        }
    }
}
</script>

<style scoped>
.gap-3 {
    gap: 12px;
}
</style>
