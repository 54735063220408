<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-bind="attrs" v-on="on">
                <v-icon size="16" class="mr-2">far fa-shopping-cart</v-icon>
                Bestellen
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-h5">
                Bestellen
            </v-card-title>

            <v-tabs
                v-model="tab"
                grow
                slider-color="primary"
            >
                <v-tab>2BA</v-tab>
                <v-tab>Foto</v-tab>
            </v-tabs>
            <v-window
                class="order-tab-window"
                v-model="tab"
            >
                <v-window-item>
                    <v-container fluid>
                        <MaterialOrder2ba :workOrderId="workOrderId" @closeDialog="dialog = false"/>
                    </v-container>
                </v-window-item>
                <v-window-item>
                    <v-container fluid>
                        <MaterialOrderPhoto :workOrderId="workOrderId" @closeDialog="dialog = false"/>
                    </v-container>
                </v-window-item>
            </v-window>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false">
                    Sluiten
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import MaterialOrder2ba from "../materialOrder/MaterialOrder2ba.vue";
import MaterialOrderPhoto from "../materialOrder/MaterialOrderPhoto.vue";

export default {
    name: "PlaceMaterialOrder",
    components: {MaterialOrderPhoto, MaterialOrder2ba},
    props: {
        workOrderId: {type: Number, default: null, required: false},
    },
    data() {
        return {
            dialog: false,
            tab: null,
        }
    },
    mounted() {
        if (this.$route.hash && this.$route.hash.slice(0, 4) === '#2ba') {
            this.dialog = true
            this.tab = 0
        }
    }
}
</script>

<style scoped>
.v-tab {
    min-width: 50px;
}
</style>
