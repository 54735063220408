const serialize = function(params)
{
    let result = []
    Object.entries(params)
        .forEach(([key, value]) => {
            result.push(encodeURIComponent(key)+'='+encodeURIComponent(value));
        });
    return result.join("&");
}


export {
    serialize
}
