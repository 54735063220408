<template>
    <v-form
        validate-on="submit"
        @submit.prevent="orderByPhoto"
        ref="fileSubmitForm"
        v-model="valid"
    >
        <p class="mt-3">Fotobestelling</p>
        <div class="d-flex flex-wrap gap-3">
            <v-text-field
                v-model="photoDescription"
                :rules="[v => v !== null && v.length > 0 || 'Vul een omschrijving in']"
                hide-details
                outlined
                dense
                min="0"
                label="Omschrijving*"
            />
        </div>
        <div class="d-flex justify-space-between mt-3">
            <v-text-field
                v-model="quantity"
                :rules="[v => v > 0 || 'Moet minimaal 1 zijn']"
                hide-details
                outlined
                dense
                type="number"
                min="0"
                label="Aantal*"
                class="flex-grow-6 me-5 amountField"
            />
            <v-select
                v-model="unit"
                :items="unitItems"
                item-text="unit"
                item-value="value"
                label="Eenheid*"
                :rules="[v => v !== null || 'Kies een eenheid']"
            ></v-select>
        </div>
        <div class="d-flex">
            <v-select
                v-model="workOrderId"
                :items="workOrders"
                item-text="voucherNumber"
                item-value="id"
                label="Werkbon"
            ></v-select>
        </div>
        <div class="d-flex flex-wrap gap-3 mt-3">
            <v-file-input
                counter
                show-size
                small-chips
                truncate-length="15"
                accept="image/*"
                outlined
                required
                dense
                v-model="photo"
            />
<!--            Afbeelding niet verplicht op vraag van klant-->
<!--            :rules="[v => v !== null || 'Kies een foto']"-->
            <v-btn color="green" class=" ml-auto" type="submit" :disabled="!valid">
                <v-icon size="16" class="mr-2">far fa-shopping-cart</v-icon>
                Bestellen
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import apiClient from "@/api";
import Compressor from "compressorjs/dist/compressor.esm"
import {mapState} from "vuex";

export default {
    name: "MaterialOrderPhoto",
    props: {
        workOrderId: {type: Number, default: null, required: false},
    },
    data() {
        return {
            quantity: null,
            photo: null,
            photoDescription: '',
            valid: false,
            unit: null,
            unitItems: [
                {unit: 'Meters', value: 'Meter'},
                {unit: 'Stuk(s)', value: 'Stuk'}
            ],
        }
    },
    mounted() {
        this.$store.dispatch('getWorkOrders')
    },
    computed: {
        ...mapState({
            workOrders: state => state.workOrder.workOrders,
        }),
    },
    methods: {
        async orderByPhoto() {
            if (!this.valid) {
                return
            }

            const formData = new FormData()
            formData.append('workOrder', this.workOrderId)
            formData.append('orderType', 'photo')
            formData.append('quantity', this.quantity)
            formData.append('photoDescription', this.photoDescription)
            formData.append('unit', this.unit)

            if (this.photo !== null) {
                let promise = new Promise((resolve, reject) => {
                    new Compressor(this.photo, {
                        quality: 0.6,
                        maxWidth: 1920,
                        maxHeight: 1080,
                        success(result) {
                            formData.append('photo', result, result.name)
                            resolve()
                        },
                        error(err) {
                            console.log(err.message);
                            reject()
                        },
                    })
                })
                await promise
            }

            const headers = {'Content-Type': 'multipart/form-data'};
            apiClient.post(`/material-orders/new-material-photo-order`, formData, {headers})
                .then(() => {
                    this.$store.dispatch('showSnackbar', {text: 'Bestelling foto geplaatst'})
                    this.$refs.fileSubmitForm.reset();
                    this.$emit('closeDialog')
                    this.$store.dispatch('getMaterialOrders')
                })
                .catch(() => {
                    this.$store.dispatch('showSnackbar', {text: 'Fout bij plaatsen bestelling', color: 'red darken-2'})
                })
        },
    },
}
</script>

<style scoped>
.gap-3 {
    gap: 12px;
}

.amountField {
    width: 100px;
}

@media screen and (max-width: 383px) {
    .amountField {
        width: 100%;
    }
}
</style>
